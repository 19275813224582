import React from 'react'
import { Header1, Header2 } from '../styles/Components'
import Button from '../components/Button'
import Head from 'next/head'
import { NotFoundWrapper } from '../components/404/styles'


const NotFoundPage = () => {
  return (
    <NotFoundWrapper>
      <Head>
        <title>Glamsquad</title>
      </Head>
      <Header1 align="center">404</Header1>
      <Header2 align="center">Not Found</Header2>
      <Button large outline margin="40px 0 0" path="/">
        Back to homepage
      </Button>
    </NotFoundWrapper>
  )
}

export default NotFoundPage
