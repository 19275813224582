import styled from '@emotion/styled'
import { gsColors } from '../../../styles/Variables'

export const NotFoundWrapper = styled.div`
  height: calc(100vh - 250px - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    color: ${gsColors.cobalt};
  }
`
